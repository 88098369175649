.dialer {
  padding: 0.5rem;
  width: 100%;
  text-align: center;

  .react-tel-input {
    margin-top: 1rem;

    .form-control {
      width: 100%;
    }
  }

  .pad-controls {
    display: flex;
    justify-content: space-around;
  }

  #sip-audio {
    display: none;
  }

  .number-input {
    margin-bottom: 1rem;
    max-width: 200px;

    button {
      margin-right: -3px;
    }
  }

  .settings {
    margin-bottom: .25rem;
    display: flex;
    justify-content: space-between;
  }

  .call-controls {
    margin: 0 auto;
    max-width: 200px;
    display: flex;
    justify-content: space-around;
  }

  .digits {
    margin: 0 auto;
    margin-bottom: 1rem;
    max-width: 200px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    row-gap: 0.25rem;
    column-gap: 0rem;
    justify-items: center;

    .digit {
      display: block;
      border: 1px solid $border-color;
      width: 4rem;
      text-align: center;
      font-size: 1rem;

      span.letters {
        white-space: nowrap;
        display: block;
        width: 2rem;
        font-size: 0.50rem;
      }
    }
  }

  .call-active-display {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;

    .avatar {
      background: $off-white;
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 150%;
      color: #999;
      border-radius: 4px;
      margin-bottom: 1rem;
    }

    .number {
      font-size: 150%;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .call-active-btns {
      display: flex;
      justify-content: space-around;
    }

    .call-ctl-btn {
      border: 1px solid $border-color;
      width: 50px;
      padding: 0.25rem;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: #eee;
        ;
      }
    }
  }
}