.profile-nav {
  padding: 1em;

  .profile-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: transparent;
    font-size: .85em;
    font-weight: 700;
    cursor: pointer;

    .profile-avatar {
      margin-left: .5em;
    }

    &:hover {
      background: $blue-white;
    }
  }
}

.nav {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}

.section-header {
  opacity: .75;
  display: block;
  font-size: .85em;
  margin: 2em 1em 1em;
}

.nav-item {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  padding: .75em 1em;

  &.active {
    background: rgba($blue-light, .25);
  }

  &:hover {
    background: rgba($blue-light, .4);
  }

  svg {
    color: $brand-green;
    font-size: 1.25em;
    margin-right: .5em;
  }
}

.link-group {
  display: flex;
  flex-direction: column;

  .channel {
    &::before {
      content: '#';
      color: $blue-light;
      display: inline-block;
      margin-right: .5em;
    }
  }
}
