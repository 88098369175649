@import 'variables.scss';
@import 'utility.scss';
@import 'chat.scss';
@import 'call-log.scss';
@import 'dialer.scss';
@import 'home.scss';
@import 'login.scss';
@import 'nav.scss';

body {
  background-color: white !important;
  font-family: $brand-font !important;
  margin: 0;
}

.dashboard-container {
  display: grid;
  grid-template-columns: $nav-column auto $action-column;
  grid-template-rows: $header-row auto;
  grid-template-areas:
    "topempty toplogo topuser"
    "leftsidebar maincontent rightsidebar";
  height: 100vh;
  max-height: 100vh;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-column: 1 / span 3;

  .logo {
    height: 100%;
    width: $nav-column;
    padding: 1em 2em;
    background: $blue-dark;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
    }
  }
}

.left-sidebar {
  background: darken($blue-dark, 5%);
  color: $off-white;
  grid-area: leftsidebar;
}

.main-content {
  background: $blue-white;
  grid-area: maincontent;
  height: (96vh-$header-row);
}

.right-sidebar {
  grid-area: rightsidebar;
}

.contact-list {
  .search {
    padding: 1em;

    &:hover {
      >div {
        &::before {
          opacity: 1;
          border-bottom: 1px solid white;
        }
      }
    }

    >div {
      &::before {
        opacity: .25;
        border-bottom-color: white;
      }
    }

    input {
      color: white;
      font-size: 1em;
    }

    svg {
      color: $blue-light;
      font-size: 1.25em;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name {
      margin-bottom: .5em;
    }

    .phone {
      color: lighten($blue-light, 40%);
      display: flex;
      align-items: center;

      svg {
        color: $blue-light;
      }
    }
  }
}