.login-container {
  min-width: 400px;
  padding: $space;
  display: flex;
  flex-direction: column;

  h1,
  p {
    text-align: center;
  }

  .MuiTextField-root {
    margin-top: $space;
  }

  .submit {
    text-align: right;
    margin-top: $space;

    button {
      background-color: $brand-dark;
      color: white;
    }
  }
}