.chat-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  height: 100%;
  margin: 0 1em;

  .header {
    color: #555;
    display: flex;
    flex-direction: column;
    font-family: $brand-font;
    margin: 1em 0;

    h3 {
      font-weight: 700;
      margin: 0;
    }

    p {
      font-size: .85em;
      margin: 0;
    }
  }

  .chat-box-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .chat-box {
    flex: 30;
    background: white;
    width: 100%;
  }

  .chat-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 78vh;
    overflow-y: scroll;
    margin-right: -14px;
    padding-right: 14px;
    padding-bottom: 88px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .chat-message {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;
    min-width: auto;

    .message-data {
      font-weight: 700;
      font-size: .85em;
      margin-bottom: .5em;
    }

    p {
      border-radius: 10px;
      color: black;
      display: inline-flex;
      min-width: 6rem;
      margin: 0;
      padding: 1em 1.5em 1.75em 1em;
      width: fit-content;
      position: relative;
    }

    .timestamp {
      white-space: nowrap;
      position: absolute;
      bottom: .5em;
      right: 1em;
      font-size: .85em;
      color: #555;
    }

    &.incoming {
      padding-right: 2em;

      p {
        background: rgba($brand-green, .5);
        border: 1px solid $brand-green;
      }
    }

    &.outgoing {
      margin-left: auto;
      padding-left: 2em;

      .message-data {
        text-align: right;
      }

      p {
        background: rgba($blue-light, .5);
        border: 1px solid $blue-light;
      }
    }
  }
}

.team-chat-roster {
  div.contact {
    padding-left: 2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;

    &.active {
      background: rgba($blue-light, .4);
    }

    &:hover {
      cursor: pointer;
      background: rgba($blue-light, .4);
    }
  }
}