$border-color: #ddd;
$off-white: #f5f7fb;

$brand-dark: #303039;

$brand-font: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$brand-green: #9FC142;

$blue-dark: #023047;
$blue-light: #219ebc;
$blue-white: #edf6f9;

$nav-column: 200px;
$action-column: 250px;

$header-row: 4.69;

$border-radius: 6px;
$space: 2rem;