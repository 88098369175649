.home-container {
  width: 100%;
  height: 100%;

  .background-img {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .home-content {
    z-index: 1;
    margin: 0 auto;
    width: 80%;
    margin-top: 15%;
    display: flex;
    justify-content: space-evenly;
    background-color: white;
    border-radius: $border-radius;
    box-shadow: 0px 0px 15px -4px #333;
    padding: $space;

    .home-pitch {
      border-radius: $border-radius;
      border-top-right-radius: 0;
      background-color: $brand-dark;
      color: white;
      padding: $space;

      .title {
        color: $brand-green;
      }

      .logo {
        width: 100%;
        padding: 1em 2em;
        background: $blue-dark;
        display: flex;
        justify-content: center;

        img {
          width: 200px;
        }
      }

      .button-container {
        margin-top: $space;
        display: flex;
        justify-content: flex-end;

        .learn-more-button {
          color: $brand-dark;
          font-weight: bold;
          background-color: $brand-green;
        }
      }


    }
  }
}